/**
 * menu 作为 route 的生成依据
 */
const source = [
  {
    name: "首页",
    index: "dashboard",
    route: "/",
    icon: "s-home",
    component: () => import("../views/dashboard.vue"),
  },
  {
    name: "设备管理",
    index: "machine",
    icon: "s-platform",
    permission: ["machine:list", "machine:create", "machine:gen-codes"],
    items: [
      {
        name: "设备列表",
        index: "machine-list",
        permission: "machine:list",
        route: "/machine/list",
        component: () => import("../views/machine/list.vue"),
      },
      {
        name: "二维码工具",
        index: "machine-qrcode",
        route: "/machine/qrcode",
        component: () => import("../views/machine/qrcode.vue"),
      },
      {
        name: "批量入录",
        index: "machine-batch",
        permission: "machine:create",
        route: "/machine/batch",
        component: () => import("../views/machine/batch-input.vue"),
      },
      {
        name: "批量条码",
        index: "batch-qrcode",
        permission: "machine:gen-codes",
        route: "/machine/batchqrcode",
        component: () => import("../views/machine/BatchQrcode.vue"),
      },
      {
        name: "设备工作时间配置",
        index: "machine-time",
        permission: "machine:time",
        route: "/machine/machinetime",
        component: () => import("../views/machine/MachineTime.vue"),
      },
    ],
  },
  {
    name: "用户管理",
    index: "user",
    permission: ["user:list", "user:role"],
    icon: "s-custom",
    items: [
      {
        name: "用户列表",
        index: "user-list",
        permission: "user:list",
        route: "/user/list",
        component: () => import("../views/user/list.vue"),
      },
      {
        name: "投放人员",
        index: "user-permission",
        permission: "user:role",
        route: "/user/permission",
        component: () => import("../views/user/permission.vue"),
      },
    ],
  },
  {
    name: "订单管理",
    index: "order",
    permission: ["order:user-order"],
    icon: "s-claim",
    items: [
      {
        name: "洗衣订单",
        index: "user-machine-order",
        permission: "order:user-order",
        route: "/order/user/machine",
        component: () => import("../views/order/user-machine.vue"),
      },
      {
        name: "充值订单",
        index: "user-recharge-order",
        permission: "order:user-order",
        route: "/order/user/recharge",
        component: () => import("../views/order/user-recharge.vue"),
      },
    ],
  },
  // {
  //   name: "支付管理",
  //   index: "payment",
  //   icon: "s-claim",
  //   items: [
  //     {
  //       name: "多方支付配置",
  //       index: "payment-manage",
  //       route: "/payment/multipleConfig",
  //       component: () => import("../views/payment/MultipleConfig.vue"),
  //     },
  //   ],
  // },
  {
    name: "客服中心",
    index: "serve",
    permission: ["serve:user-feedback", "serve:user-refund"],
    icon: "s-comment",
    items: [
      {
        name: "用户反馈",
        index: "user-feedback",
        permission: "serve:user-feedback",
        route: "/serve/feedback",
        component: () => import("../views/serve/feedback.vue"),
      },
      {
        name: "退单处理",
        index: "user-refund",
        permission: "serve:user-refund",
        route: "/serve/refund",
        component: () => import("../views/serve/refund.vue"),
      },
    ],
  },
  {
    name: "报表管理",
    index: "report",
    permission: ["report:total", "report:college-detail"],
    icon: "s-data",
    items: [
      {
        name: "数据概览",
        index: "report-college-total",
        permission: "report:total",
        route: "/report/college/total",
        component: () => import("../views/report/college-order-total.vue"),
      },
      {
        name: "订单日报表",
        index: "report-college-detail",
        permission: "report:college-detail",
        route: "/report/college/detail",
        component: () => import("../views/report/college-order-detail.vue"),
      },
    ],
  },
  {
    name: "活动管理",
    index: "recharge",
    permission: ["recharge:template", "recharge:college"],
    icon: "s-claim",
    items: [
      {
        name: "模版管理",
        index: "recharge-template",
        permission: "recharge:template",
        route: "/recharge/template",
        component: () => import("../views/recharge/template.vue"),
      },
      {
        name: "学校配置",
        index: "recharge-college",
        permission: "recharge:college",
        route: "/recharge/college",
        component: () => import("../views/recharge/relation.vue"),
      },
    ],
  },
  {
    name: "会员卡管理",
    index: "pay",
    permission: ["card:list", "card:order"],
    icon: "s-finance",
    items: [
      {
        name: "会员卡列表",
        index: "pay-card",
        permission: "card:list",
        route: "/pay/card",
        component: () => import("../views/pay/card.vue"),
      },
      {
        name: "会员卡订单",
        index: "pay-order",
        permission: "card:order",
        route: "/pay/order",
        component: () => import("../views/pay/order.vue"),
      },
    ],
  },
  {
    name: "网点管理",
    index: "point",
    permission: ["point:college", "point:block", "merchant:configs"],
    icon: "s-platform",
    items: [
      {
        name: "学校管理",
        index: "point-college",
        permission: "point:college",
        route: "/point/college",
        component: () => import("../views/point/college.vue"),
      },
      {
        name: "楼栋管理",
        index: "point-block",
        permission: "point:block",
        route: [
          { name: "point-block", path: "/point/block" },
          { name: "point-block-by-id", path: "/point/block/:collegeId" },
        ],
        component: () => import("../views/point/block.vue"),
      },
      {
        name: "多方支付配置",
        index: "payment-manage",
        permission: "merchant:configs",
        route: "/payment/multipleConfig",
        component: () => import("../views/payment/MultipleConfig.vue"),
      },
      {
        name: "网点授权",
        index: "point-authorize",
        permission: "point:authorize",
        route: "/point/authorize",
        component: () => import("../views/point/Authorize.vue"),
      },
    ],
  },
  {
    name: "账号管理",
    index: "system",
    permission: [
      "system:admin-user",
      "system:admin-role",
      "system:admin-permission",
      "system:config",
    ],
    icon: "setting",
    items: [
      {
        name: "账号列表",
        index: "admin-user",
        permission: "system:admin-user",
        route: "/admin/user",
        component: () => import("../views/admin/user.vue"),
      },
      {
        name: "角色配置",
        index: "admin-role",
        permission: "system:admin-role",
        route: "/admin/role",
        component: () => import("../views/admin/role.vue"),
      },
      {
        name: "权限",
        index: "admin-permission",
        permission: "system:admin-permission",
        route: "/admin/permission",
        component: () => import("../views/admin/permission.vue"),
      },
      {
        name: "系统配置",
        index: "system-config",
        permission: "system:config",
        route: "/system/config",
        component: () => import("../views/admin/system-config.vue"),
      },
    ],
  },
  {
    name: "配置管理",
    index: "configuration",
    permission: [
      "configuration:price-mould",
      "configuration:price-distribution",
    ],
    icon: "aim",
    items: [
      {
        name: "价格模板",
        index: "price-mould",
        permission: "configuration:price-mould",
        route: "/configuration/pricemould",
        component: () => import("../views/configuration/PriceMould.vue"),
      },
      {
        name: "分配价格模板",
        index: "price-distribution",
        permission: "configuration:price-distribution",
        route: "/configuration/distributionmould",
        component: () => import("../views/configuration/DistributionMould.vue"),
      },
    ],
  }
];

const getMenuRoute = (item) => {
  if (Array.isArray(item.route)) {
    for (const iterator of item.route) {
      if (iterator.name === item.index) {
        return iterator.path;
      }
    }
  } else {
    return item.route;
  }
};
const flat = source
  .map((item) => {
    if (item.items) {
      return item.items;
    }
    return item;
  })
  .flat();

const routes = flat
  .map((item) => {
    if (Array.isArray(item.route)) {
      return item.route.map((r) => {
        const temp = { ...r };
        temp.component = item.component;
        temp.meta = { menu: item };
        return temp;
      });
    }
    return {
      name: item.index,
      path: item.route,
      component: item.component,
      meta: { menu: item },
    };
  })
  .flat();

console.log(source,333333);
console.log(flat);
console.log(routes);

export { source, flat, routes, getMenuRoute };
