import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import apis from './api/apis.js';
import token from './utils/permission';

import 'dayjs/locale/zh-cn';
import locale from 'element-plus/lib/locale/lang/zh-cn';

const app = createApp(App);
app.config.globalProperties.$api = apis;

app.use(store).use(router).use(ElementPlus, { locale });

// app.mount('#app');

if (sessionStorage.token) {
  token.init(sessionStorage.token).then(() => {
    app.mount('#app');
  });
} else {
  app.mount('#app');
}

// admin 1
// const loginToken = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiMSIsInN1YiI6IjEiLCJpc3MiOiJtc3NlcnZlciIsImlhdCI6MTYwODU2MzM0OCwiYXVkIjoiY2xpZW50IiwiZXhwIjoxNjE3MTE2OTQ4fQ.evCbEaOPzj5s_-oBlD4OhpOSiJLpUarWWhC4zTRu6eE';
// admin 2
// const loginToken = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiMSIsInN1YiI6IjIiLCJpc3MiOiJtc3NlcnZlciIsImlhdCI6MTYwODU2MzM1MCwiYXVkIjoiY2xpZW50IiwiZXhwIjoxNjE3MTE2OTUwfQ.7xnz5fInKFYoFANDa6Vd9uY2XYyLgAHQsjjgxE-oZac';
// 登录后调用
// token.init(loginToken).then(() => {
//   // 跳转到主页
//   app.mount('#app');
// });
