<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">

@import './color.scss';

body, html {
  width: 100%;
  height: 100%;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;

  /* height: 4px; */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.main-form-item-float-right {
  float: right;
  margin-right: 0 !important;
}

.main-el-dialog {
  max-width: 90%;
}

.dialog-el-transfer {
  .el-transfer__buttons {
    padding: 0 10px;
  }

  .el-button {
    padding: 10px;
  }

  .el-transfer-panel {
    width: 180px;
  }
}

.el-overlay {
  height: 100%;
  overflow-x: auto;
}

.el-table__expanded-cell {
  padding: 10px 20px;

  .el-form-item {
    margin: 0;
  }
}

</style>
