import { createStore } from 'vuex';

const user = {
  status: [{
    'ordinal': 1,
    'name': 'Wait',
    'des': '待支付'
  }, {
    'ordinal': 2,
    'name': 'Paid',
    'des': '已支付'
  }, {
    'ordinal': 3,
    'name': 'Cancel',
    'des': '已取消'
  }, {
    'ordinal': 4,
    'name': 'Refund',
    'des': '已退款'
  }, {
    'ordinal': 5,
    'name': 'Complete',
    'des': '已完成'
  }]
};

const userStatus = {};
user.status.forEach(item => {
  userStatus[item.name] = item.des;
});

const pay = {
  method: {
    '1': '余额支付',
    '2': '微信支付'
  },
  status: [{
    'ordinal': 1,
    'name': 'Wait',
    'des': '待支付'
  }, {
    'ordinal': 2,
    'name': 'Complete',
    'des': '已完成'
  }, {
    'ordinal': 3,
    'name': 'Cancel',
    'des': '已取消'
  }],
  type: [{
    'ordinal': 1,
    'name': 'Consume',
    'des': '消费'
  }, {
    'ordinal': 2,
    'name': 'Recharge',
    'des': '充值'
  }, {
    'ordinal': 3,
    'name': 'Refund',
    'des': '退款'
  }]
};

const payMethod = pay.method;

const payType = {};
pay.type.forEach(item => {
  payType[item.name] = item.des;
});

const payStatus = {};
pay.status.forEach(item => {
  payStatus[item.name] = item.des;
});

const store = createStore({
  state: {
    gender: {0: '未知', 1: '男', 2: '女'},
    userType: {'Machine': '洗衣支付', 'Recharge': '充值'},
    userStatus,
    userRole: {1: '安装人员', 2: '测试人员'},
    userRefundType: {Refund: '原路返回', Transfer: '转账'},
    userRefundGType: {System: '系统', User: '用户', Admin: '员工'},
    userRefundStatus: {Wait: '待审核', Pass: '通过', Reject: '拒绝'},
    payMethod,
    payType,
    payStatus,
    feedbackType: {1: '订单问题反馈', 2: '建议与意见'},
    feedbackStatus: {1: '未处理', 2: '已处理'},
    machineStatus: {0: '未知', 1: '空闲', 2: '离线', 3: '待启动', 4: '运行中', 5: '异常'},
    positionType: {1: '洗衣房', 2: '寝室'},
    rechargeTemplateStatus: {1: '编辑中', 2: '已上线', 3: '已下线'},
    rechargeCollegeStatus: {1: '编辑中', 2: '已上线', 3: '已下线'},

    map: {
      college: [],
      machineType: []
    }
  },
  getters: {
    columnIdToVal: state => (key, scope) => {
      return state[key][scope.row[scope.column.property]];
    },
    optionMap: state => key => {
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
});

export default store;
