import { createRouter, createWebHashHistory } from "vue-router";
import layout from "../views/layout.vue";
import { routes as menuRoutes } from "./menu";

// const menuTempData = new Array.push.apply([], menus.map(item => item.items))

const routes = [
  {
    path: "/",
    name: "layout",
    component: layout,
    children: menuRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/questionList",
    name: "问题列表",
    component: () => import("../views/mobile/QuestionList.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
