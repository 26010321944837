
import apis from '../api/apis';

const token = {
  source: null,
  body: {},
  permission: [],
  user:{},
  init(newToken,user) {
    token.source = newToken;
    token.user=user;
    token.body = JSON.parse(atob(newToken.split('.')[1]));
    return apis.getAdminUserPermissions().then(data => {
      token.permission = data;
    });
  },
  hasAny(...permission) {
    if (this.body.sub === '1') {
      return true;
    }
    for (const p of permission) {
      if (token.permission.includes(p)) {
        return true;
      }
    }
    return false;
  },
  isLogin() {
    return this.source != null;
  },
  logout() {
    this.source = null;
    this.body = {};
    this.user={};
    this.permission = [];
  }
};

export default token;
