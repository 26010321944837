import { get, post, put, del } from "./http";

const apis = {
  getWXQRCode(data) {
    return get("wx/qrcode", data);
  },

  // 报表
  getReportCollegeOrderPage(data) {
    return get("report/college/order/page", data);
  },
  getReportCollegeOrderTotal(data) {
    return get("report/college/order/total", data);
  },
  getReportCollegeOrderDay7(data) {
    return get("report/college/order/day7", data);
  },
  getMachineReport(data) {
    return get("report/machine", data);
  },

  // 退单
  getOrderRefundApplyPage(data) {
    return get("user/refund/apply/page", data);
  },
  updateOrderRefundApply(data) {
    return put("user/refund/apply", data);
  },
  createOrderRefundApply(data) {
    return post("user/refund/apply", data);
  },

  // 支付
  getPayCardPage(data) {
    return get("pay/card/page", data);
  },
  getPayOrderPage(data) {
    return get("pay/order/page", data);
  },
  getPayOrderLocalPage(data) {
    return get("pay/order/local/page", data);
  },

  // 机器
  getMachinePage(data) {
    return get("machine/page", data);
  },
  getMachineReal(data) {
    return get("machine/real", data);
  },
  getMachinePosition(data) {
    return get("machine/position", data);
  },
  deleteMachinePosition(machineId) {
    return del("machine/position", { machineId });
  },
  getMachineLogs(data) {
    return get("machine/logs", data);
  },
  createMachine(data) {
    return post("machine", data);
  },
  createMachineBatch(data) {
    return post("machine/batch", data);
  },
  deleteMachine(id) {
    return del("machine", { id });
  },
  updateMachine(data) {
    return put("machine", data);
  },
  getMachineRunOrder(data) {
    return get("machine/run/order", data);
  },

  // 用户
  getUserPage(data) {
    return get("user/page", data);
  },
  getUserOrderPage(data) {
    return get("user/order/page", data);
  },
  getUserFeedbackPage(data) {
    return get("user/feedback/page", data);
  },
  updateUserFeedbackStatus(id, status) {
    return put("user/feedback/status", { id, status });
  },

  // 用户角色
  getUserRolePage(data) {
    return get("user/role/page", data);
  },
  createUserRole(data) {
    return post("user/role", data);
  },
  deleteUserRole(id) {
    return del("user/role", { id });
  },

  // 充值
  getRechargePage(data) {
    return get("recharge/page", data);
  },
  getRecharge(data) {
    return get("recharge", data);
  },
  createRecharge(data) {
    return post("recharge", data);
  },
  updateRecharge(data) {
    return put("recharge", data);
  },
  updateRechargeStatus(id, status) {
    return put("recharge/status", { id, status });
  },
  getRechargeParams(templateId) {
    return get("recharge/params", { templateId });
  },
  getRechargeRelationPage(data) {
    return get("recharge/relation/page", data);
  },
  createRechargeRelation(data) {
    return post("recharge/relation", data);
  },
  updateRechargeRelation(data) {
    return put("recharge/relation", data);
  },
  delRechargeRelation(id) {
    return del("recharge/relation", { id });
  },

  // 学校
  getCollegePage(data) {
    return get("point/college/page", data);
  },
  getCollege(data) {
    return get("point/college", data);
  },
  createCollege(data) {
    return post("point/college", data);
  },
  updateCollege(data) {
    return put("point/college", data);
  },
  deleteCollege(id) {
    return del("point/college", { id });
  },

  // 学校楼栋
  getCollegeBlockPage(data) {
    return get("point/college/block/page", data);
  },
  getCollegeBlock(data) {
    return get("point/college/block", data);
  },
  createCollegeBlock(data) {
    return post("point/college/block", data);
  },
  updateCollegeBlock(data) {
    return put("point/college/block", data);
  },
  deleteCollegeBlock(id) {
    return del("point/college/block", { id });
  },

  // admin user
  getAdminUserPage(data) {
    return get("admin/user/page", data);
  },
  getAdminUser(data) {
    return get("admin/user", data);
  },
  getAdminUserPermissions() {
    return get("admin/user/permissions");
  },
  createAdminUser(data) {
    return post("admin/user", data);
  },
  updateAdminUser(data) {
    return put("admin/user", data);
  },
  deleteAdminUser(id) {
    return del("admin/user", { id });
  },
  updateAdminUserPassword(data) {
    return post("admin/user/password", data);
  },

  // admin role
  getAdminRolePage(data) {
    return get("admin/role/page", data);
  },
  getAdminRole(data) {
    return get("admin/role", data);
  },
  getAllAdminRole(data) {
    return get("admin/roles", data);
  },
  getAdminRolePermissions(id) {
    return get("admin/role/permissions", { roleId: id });
  },
  createAdminRole(data) {
    return post("admin/role", data);
  },
  updateAdminRole(data) {
    return put("admin/role", data);
  },
  deleteAdminRole(id) {
    return del("admin/role", { id });
  },

  // 系统配置
  getSystemConfigs(data) {
    return get("system/configs", data);
  },
  updateSystemConfig(data) {
    return put("system/config", data);
  },

  // 列表
  getCollegeMap() {
    return get("common/map/college");
  },
  getMachineTypeMap() {
    return get("common/map/machine/type");
  },
  getRechargeMap() {
    return get("common/map/recharge/template");
  },

  // 权限
  getAllAdminPermissions() {
    return get("admin/permissions");
  },
  // 登录
  login(data) {
    return post("admin/login", data);
  },
  // 获取批量条码记录
  getBatchCodeList(params) {
    return get("/machine/gen/codes", params);
  },
  // 批量生成条码
  batchCreateCode(data) {
    return post("/machine/gen/code", data);
  },
  // 下载条码
  downloadCode(params) {
    return get("/machine/down/codes", params, false, "blob");
  },
  // 查看条码明细
  checkCodeDetail(params) {
    return get("/machine/gen/codeitem", params);
  },
  // 获取支付配置记录
  getPaymentConfigList(params) {
    return get("/merchant/configs", params);
  },
  // 新增支付配置
  createPaymentConfig(data) {
    return post("/merchant/config", data);
  },
  // 修改支付配置
  updatePaymentConfig(data) {
    return put("/merchant/config", data);
  },
  // 启用支付配置
  onPaymentConfig(data) {
    return put("/merchant/config/on" + data);
  },
  // 禁用支付配置
  offPaymentConfig(data) {
    return put("/merchant/config/off" + data);
  },
  // 分流设置
  ratePaymentConfig(data) {
    return put("/merchant/config/rate" + data);
  },

  //配置管理

  //查询费用模板
  getPriceMouldList(params){
    return get("/price/template" , params);
  },
  //添加费用模板
  addPriceMould(data){
    return post("/price/template" , data);
  },
  //添加费用模板
  editPriceMould(data){
    return put(`/price/template/${data.id}` , data);
  },
   //删除费用模板
   delPriceMould(id){
    return del(`/price/template/${id}`);
  },
  //恢复费用模板
  resumePriceMould(id){
    return put(`/price/template/recovery/${id}`);
  },
  //启用模板
  statMould(id){
    return put(`/price/template/start/${id}`);
  },
  //停用模板
  stopMould(id){
    return put(`/price/template/stop/${id}`);
  },
  // 获取洗衣机类型
  getMachineType(){
    return get(`/common/map/machine/type`);
  },
  // 获取命令集合
  getActionList(params){
    return get(`/machine/actions`,params);
  },
  // 获取学校列表
  getSchoolList(){
    return get(`/common/map/college`);
  },
  // 查询学校已经关联的模板
  getCorrelationMould(params){
    return get(`price/template/collage/${params.colleageId}`,params);
  },
  // 学校关联模板删除
  delCorrelationMould(id){
    return del(`/price/template/colleage/${id}`);
  },
   // 学校关联模板恢复
   resumeCorrelationMould(id){
    return put(`/price/template/colleage/recovery/${id}`);
  },
  // 查询学校未关联的模板
  getNotCorrelationMould(id){
    return get(`price/template/nocolleage/${id}`);
  },
  // 模板关联学校
  agreeCorrelationMould(data){
    return put(`/price/template/colleage?colleage_id=${data.colleage_id}&template_id=${data.template_id}`);
  },

  //网点管理

  // 已分配网点
  getDistributedPoint(id,params){
    return get(`/point/college/user/dispense/${id}`,params);
  },
   // 未分配网点
   getUnallocateList(id,params){
    return get(`/point/college/user/undispense/${id}`,params);
  },
   // 分配网点
  allocatePoint(id,data){
    console.log(data,34444)
    return post(`/point/college/user/${id}`,data);
  },
  //删除分配网点
  delAllocatePoint(id,params){
    return del(`/point/college/user/${id}`,params);
  },

  // 设备工作时间配置
  getMachineTimeList(params){
    return get(`/machine/work/range`,params);
  },
  // 设置工作时间范围
  setMachineTime(data) {
    return put("/machine/work/range", data);
  },
  // 验证
  getMachineStatus(no){
    return get(`/machine/work/able/${no}`);
  }
};
export default apis;
