import axios from "axios";
import { ElLoading } from "element-plus";
import token from "../utils/permission";
import router from "../router";
function startLoading() {
  // 使用Element loading-start 方法
  return ElLoading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.7)",
  });
}
function cancelLoading({ loading }) {
  // 使用Element loading-close 方法
  if (loading.inst) {
    loading.inst.close();
  }
  if (loading.timeout) {
    clearTimeout(loading.timeout);
  }
}
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? process.env.VUE_APP_BASE_API
      : process.env.VUE_APP_ONLINE_API,
  timeout: 500000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // config.headers['Access-Control-Allow-Origin'] = true;
    console.log(token);
    if (token.source) {
      config.headers.authorization = token.source;
      // config.headers.authorization = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaXNzIjoibXNzZXJ2ZXIiLCJpYXQiOjE2MDY3OTE5OTAsImF1ZCI6ImNsaWVudCIsImV4cCI6MTYxNTM0NTU5MH0.9gTHe-sb-TdA_SlfNSXttTxMvfX3YsfcZuQdWSEX62k';
    }
    config.isForm
      ? (config.headers["content-type"] = "application/x-www-form-urlencoded")
      : (config.headers["content-type"] = "application/json");
    config.loading = {};
    if (config.method === "get") {
      config.loading.timeout = setTimeout(() => {
        config.loading.inst = startLoading();
        config.loading.timeout = undefined;
      }, 1000);
    } else {
      config.loading.inst = startLoading();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    cancelLoading(response.config);
    return response.data;
  },
  (error) => {
    let response = error.response;
    if (response.data.status === "UNAUTHORIZED") {
      router.push("/login");
    }
    console.log(error.response, "response");
    cancelLoading(error.config);
    return Promise.reject(error);
  }
);

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function post(url, params = {}, isForm = false) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "post",
      data: params,
      isForm,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function get(url, params = {}, isForm = false, responseType = "json") {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "get",
      params: params,
      isForm,
      responseType: responseType,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * put 与 post 类似
 */
export function put(url, params = {}, isForm = false) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "put",
      data: params,
      isForm,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * delete 与 get 类似
 */
export function del(url, params = {}, isForm = false) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: "delete",
      params: params,
      isForm,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
