<template>
  <el-container class="app-container">
    <el-aside width="auto">
      <el-menu
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        class="main-menu-aside"
        :default-active="$route.name"
        :collapse="isCollapse"
        unique-opened
        router
        :collapse-transition="false"
        @select="handleSelect"
      >
        <template v-for="rootMenu in menus" :key="rootMenu">
          <el-submenu v-if="rootMenu.items" :index="rootMenu.index">
            <template #title>
              <i :class="['el-icon-' + rootMenu.icon]" />
              <span>{{ rootMenu.name }}</span>
            </template>
            <el-menu-item
              v-for="menuItem in rootMenu.items"
              :key="menuItem"
              :index="menuItem.index"
              :route="{ name: menuItem.index }"
            >
              {{ menuItem.name }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :index="rootMenu.index"
            :route="{ name: rootMenu.index }"
          >
            <i :class="['el-icon-' + rootMenu.icon]" />
            <template #title>{{ rootMenu.name }}</template>
          </el-menu-item>
        </template>

        <!-- <el-submenu index="system">
        <template #title>
          <i class="el-icon-location" />
          <span>系统管理</span>
        </template>
        <el-menu-item index="userList" :route="{ name: 'userList' }">管理员</el-menu-item>
        <el-menu-item index="1-2">选项2</el-menu-item>
        <el-menu-item index="1-3">选项3</el-menu-item>
        <el-submenu index="1-4">
          <template #title>选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu>
      </el-submenu> -->
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="main-header" height="40px">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12">
            <el-row type="flex" class="row-bg">
              <el-button
                icon="el-icon-s-unfold"
                v-if="isCollapse"
                @click="isCollapse = false"
              />
              <el-button
                icon="el-icon-s-fold"
                v-if="!isCollapse"
                @click="isCollapse = true"
              />
              <el-button
                icon="el-icon-s-home"
                v-if="$route.name !== 'dashboard'"
                @click="$router.push({ name: 'dashboard' })"
              />
              <el-button type="text" class="main-header-name">{{
                $route.meta.menu.name
              }}</el-button>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row type="flex" class="row-bg" justify="end">
              <el-dropdown trigger="click">
                <el-button icon="el-icon-user-solid">{{
                  adminUser.username
                }}</el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="logout">登出</el-dropdown-item>
                    <el-dropdown-item @click="passwordDialog.visibled = true"
                      >修改密码</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-row>
          </el-col>
        </el-row>
      </el-header>
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :route="{ path: '/' }">home</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in menuPath" :key="item">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb> -->
      <el-main>
        <router-view />
      </el-main>
      <el-footer style="height: 20px;">&copy; 2021</el-footer>
    </el-container>
  </el-container>

  <el-dialog
    custom-class="main-el-dialog"
    title="修改密码"
    v-model="passwordDialog.visibled"
    width="600px"
  >
    <el-form :model="passwordDialog.form" ref="passwordForm" label-width="80px">
      <el-form-item
        label="旧密码"
        prop="old"
        :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
      >
        <el-input v-model="passwordDialog.form.old" show-password />
      </el-form-item>
      <el-form-item
        label="新密码"
        prop="password"
        :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
      >
        <el-input v-model="passwordDialog.form.password" show-password />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="passwordDialog.visibled = false">取消</el-button>
        <el-button type="primary" @click="submitPassword">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { source as sourceMenus } from "../router/menu";
import isMobile from "ismobilejs";
import permission from "../utils/permission";
import { handleCatchStatus } from "../api/handler";

export default {
  name: "App",
  data() {
    return {
      adminUser: {},
      isCollapse: true,
      menuPath: [],
      menus: [],
      passwordDialog: {
        visibled: false,
        form: {},
      },
    };
  },
  created() {
    this.adminUser = permission.user || {};
    const check = isMobile(window.navigator);
    this.isCollapse = check.phone || check.tablet;
    this.menus = sourceMenus.filter(this.filterFn);
    this.menus.forEach((item) => {
      if (item.items) {
        item.items = item.items.filter(this.filterFn);
      }
    });
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.menuPath = keyPath.value;
    },
    filterFn(item) {
      if (!item.permission) {
        return true;
      }
      if (Array.isArray(item.permission)) {
        return permission.hasAny(...item.permission);
      }
      return permission.hasAny(item.permission);
    },
    logout() {
      permission.logout();
      this.$router.push("/login");
    },
    submitPassword() {
      this.$refs.passwordForm.validate().then(() => {
        this.$api
          .updateAdminUserPassword(this.passwordDialog.form)
          .then(() => {
            this.$message.success("操作成功");
            this.passwordDialog.visibled = false;
            this.logout();
          })
          .catch(handleCatchStatus);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  overflow: hidden;
  height: 100%;
}

.main-menu-aside {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-menu-aside:not(.el-menu--collapse) {
  width: 200px;
}

.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 20px;
}

.el-aside {
  background-color: #fff;
  color: #333;

  /* text-align: center; */
}

.main-header {
  padding: 0;

  .main-header-name {
    color: #303133;
    cursor: default;
  }
}

.main-header .el-button {
  margin: 0;
  border-radius: 0;
  border-width: 0;
  padding: 12px;
}

.el-breadcrumb {
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 14px;
  margin-left: 20px;
}
</style>
