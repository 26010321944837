import { ElMessage } from "element-plus";

export function handleCatchStatus(error) {
  const response = error.response;
  // if (response.status === 200 || response.status === 204) {
  //   return response.data
  // }

  if (response.status === 400) {
    ElMessage.error(response.data.message);
    return response.data;
  }
  if (response.status === 401) {
    console.log("权限错误");
    ElMessage.warning(response.data.message);
    return response.data;
  }
  // 这是需要处理的异常
  ElMessage.error(response.data.message);
  console.log(response);
  return response.data;
}
